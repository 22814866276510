import axios from "axios";
import moment from "moment";

export default {
    namespaced: true,
    state: {
        baseServerUrl: process.env.VUE_APP_BASE_SERVER_URL, // API server Domain
        serverUrl: null, // API server URL
        locale: process.env.VUE_APP_LOCALE, // the locale the app is running on
        pageReload: process.env.VUE_APP_PAGE_RELOAD, // minutes until page reloads
        helpFile: 'schuelerportal_hilfe-schueler_2023-08_20230808.pdf', // name of help file
    },
    getters: {
        getHelpLink(state) {
            return state.baseServerUrl + '/' + state.helpFile;
        }
    },
    mutations: {
        addSchoolToServerUrl(state, school) {
            state.serverUrl = state.baseServerUrl + '/' + school;
        },
    },
    actions: {
        config({state, commit}) {
            // configure moment
            moment.locale(state.locale);
            // set page reload
            if (state.pageReload > 0) {
                setTimeout(() => location.reload(), state.pageReload * 60 * 1000)
            }
            // configure axios
            axios.defaults.withCredentials = true;
            axios.defaults.withXSRFToken = true;
            // add a response interceptor to logout user on 401 response
            axios.interceptors.response.use(function (response) {
                return response;
            }, function (error) {
                let validationErrors, relevantErrorKeys;

                switch (error.response.status) {
                    case 400:
                        if (error.response.data.message === 'Test period expired') {
                            alert('Die Testphase ist abgelaufen. Das Schüler-Portal steht bis auf Weiteres nicht zur Verfügung.')
                        }
                        break;
                    case 401: // Unauthorized
                        commit('auth/setUser', null, {root: true});
                        break;
                    case 403: // Forbidden
                        if (error.response.data.message === 'Your email address is not verified.') {
                            commit('auth/setVerified', false, {root: true});
                        } else {
                            commit('auth/setVerified', null, {root: true});
                        }
                        break;
                    case 422: // Validation error
                        // Email and password validation errors are not relevant for this interceptor case
                        // as they have to be handled by the respective views (auth blades)
                        relevantErrorKeys = Object.keys(error.response.data.errors)
                            .filter(key => !['email', 'password'].includes(key));

                        if (error.response.data.message === 'The given data was invalid.'
                            && relevantErrorKeys.length > 0) {
                            let message = '';
                            for (const validationErrorKey of relevantErrorKeys) {
                                validationErrors = error.response.data.errors[validationErrorKey]
                                for (const validationError of validationErrors) {
                                    message += `${validationError}` + '\n\n';
                                }
                            }
                            alert(message);
                        }
                        break;
                    case 503: // Service unavailable = maintenance
                        alert('Das Portal ist aktuell nicht erreichbar.\n' + error.response.data.message);
                        break;
                }

                return Promise.reject(error);
            });
        }
    },
    modules: {}
}
