<template>
  <sui-message class="info" v-if="schoolInfo && schoolInfo.test_period">
    Kostenlose Testversion bis {{ schoolInfo.test_period }}.
  </sui-message>
</template>

<script>
import store from "../store";

export default {
  name: "TestPeriod",
  computed: {
    schoolInfo() {
      return store.state.auth.schoolInfo;
    }
  },
}
</script>