<template>
  <loader v-if="loading"/>

  <top-menu :user="user" :auth="auth"/>

  <div id="body">
    <test-period/>

    <router-view v-if="auth || isNonAuthRoute"/>

    <template v-else>
      <div class="ui segment">
        <h2>Willkommen im Schüler-Portal.</h2>
        <router-link to="/login">
          <u>Hier geht's zum Login</u>
        </router-link>
      </div>
    </template>
  </div>

  <bottom-menu v-if="auth && initialized"/>

</template>

<script>
import TopMenu from "./components/TopMenu";
import BottomMenu from "./components/BottomMenu";
import Loader from "./components/Loader";
import TestPeriod from "./components/TestPeriod";
import store from "./store";

export default {
  components: {TestPeriod, BottomMenu, TopMenu, Loader},
  computed: {
    auth() {
      return store.getters['auth/check'];
    },
    user() {
      return store.state.auth.user;
    },
    isNonAuthRoute() {
      return store.getters['auth/isNonAuthRoute'](this.$route);
    },
    loading() {
      return store.state.loading.active;
    },
    initialized() {
      return store.state.initialized;
    }
  },
  created() {
    store.dispatch('config/config');
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#body {
  margin: auto;
  padding: 3rem 1rem 5rem;
  max-width: 768px;
}

a {
  color: black !important;
}

.asam-blue {
  border-color: rgba(25, 88, 191, 1.0) !important;
  background: rgba(25, 88, 191, 0.15) !important;
}

.asam-orange {
  border-color: rgba(229, 79, 14, 1.0) !important;
  background: rgba(229, 79, 14, 0.15) !important;
}

.asam-red {
  border-color: rgba(219, 22, 41, 1.0) !important;
  background: rgba(219, 22, 41, 0.15) !important;
}

.asam-green {
  border-color: rgba(149, 144, 7, 1.0) !important;
  background: rgba(149, 144, 7, 0.15) !important;
}

.asam-yellow {
  border-color: rgba(249, 183, 34, 1.0) !important;
  background: rgba(249, 183, 34, 0.15) !important;
}

.asam-purple {
  border-color: rgba(153, 114, 192, 1.0) !important;
  background: rgba(153, 114, 192, 0.15) !important;
}
</style>
